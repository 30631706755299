<template>
  <v-card tile :height="height" style="background-color: #242939" class="d-flex align-center justify-center">
    <v-card max-width="400" class="mx-5 px-2">
      <v-card-title primary-title class="text-h6 pb-lg-0 pt-lg-12 justify-center font-weight-bold"> Login </v-card-title>
      <v-form ref="loginForm">
        <v-container>
          <v-row>
            <v-col cols="12" lg="11" class="py-0 mx-lg-auto">
              <v-text-field prepend-icon="mdi-city" label="Institute" v-model="loginForm.domain" v-if="!$route.params.domain"></v-text-field>
            </v-col>
            <v-col cols="12" lg="11" class="py-0 mx-lg-auto">
              <v-text-field prepend-icon="mdi-account" label="Email" v-model="loginForm.email"></v-text-field>
            </v-col>
            <v-col cols="12" lg="11" class="py-0 mx-lg-auto">
              <v-text-field
                v-model="loginForm.password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                label="Password"
                prepend-icon="mdi-lock"
                @click:append="showPassword = !showPassword"
                @keydown.enter="login"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="7" class="pt-2 mx-lg-auto">
              <v-btn color="primary" large block @click="login()" :disabled="loading" :loading="loading">Enter</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-card-actions class="py-lg-7 px-lg-7">
        <p class="primary--text">Forgot Password?</p>
      </v-card-actions>
    </v-card>
  </v-card>
</template>

<script>
export default {
  data () {
    return {
      showPassword: false,
      loginForm: {},
      loading: false,
      height: window.innerHeight
    }
  },
  mounted () {
    window.onresize = () => {
      this.height = window.innerHeight
    }
  },
  methods: {
    login () {
      if (this.$route.params.domain) delete this.loginForm.domain
      this.loading = true
      this.$_execute('post', 'login', this.loginForm)
        .then(({ data } = {}) => {
          if (data) this.$store.commit('workspacelogin', data)
        })
        .finally(() => {
          this.loading = false
        })
      // this.$_execute('post', `${ this.$route.params.domain || this.loginForm.domain }/login`, this.loginForm)
      //   .then(({ data }) => {
      //     this.$store.commit('login', data)
      //     this.$socket.emit('login', data.user)
      //     this.$root.$emit('snackbar', { message: 'Logged In Successfully', color: 'success' })
      //   })
      //   .finally(() => {
      //     this.loading = false
      //   })
    }
  }
}
</script>
